module.exports = function (userAnswers, questionParams, x){
	let L = 0;
	let p = 0;
	let u = 0;

	for (let key in questionParams) {
		let params = questionParams[key];
		
		if (isNaN(params)) {
			p = 1 / (1 + Math.exp(-params.alpha * (x - params.beta)));
			u = userAnswers[key-1]
			L += Math.log(Math.pow(p, u) * Math.pow(1 - p, 1 - u));
		}
	}

	return -L;
};

