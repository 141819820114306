const MAX_THETA = 4;

module.exports = function (userAnswers, questionParams, func){
	const h = 1e-3;
	const STOP = 1e-7;
	const MAX_ITER = 1000;
	let x = 0;
	let d_log = 1;
	let n = 0;
	let p = 20;

	while ((n < MAX_ITER) && Math.abs(d_log) > STOP && Math.abs(p) > 10) {
		n += 1;
		d_log = (func(userAnswers, questionParams, x + h) - func(userAnswers, questionParams, x - h));
		p = func(userAnswers, questionParams, x);
		dd_log = (func(userAnswers, questionParams, x + 2*h) + func(userAnswers, questionParams, x - 2*h) - 2*p);
		x = x - 2*h * d_log / dd_log;
		if (x > MAX_THETA)
			x = MAX_THETA;
		if (x < -MAX_THETA)
			x =- MAX_THETA;
	}
	
	return x;
}